import React from "react";
import { Helmet } from "react-helmet";
import usePageTitle from "../../hooks/usePageTitle";
import { getTokenData } from "../../utils/tokenUtils";
import { getRoleById } from "../../utils";


// todo - this should be removed and useUserRole can be used 
const user = getTokenData();
export const currentRole = getRoleById(user?.role_id || "");

const HelmetComponent = () => {
  const pageTitle = usePageTitle();
  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

export default HelmetComponent;