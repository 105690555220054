export function getPageTitle(path, currentRole) {
    switch (path) {
        // User Auth Routes
        case "/":
            return "Login | Travel Nurse 911";
        case "/register":
            return "Register | Travel Nurse 911";
        case "/email-verification":
            return "Email Verification | Travel Nurse 911";
        case "/forgot-password":
            return "Forgot Password | Travel Nurse 911";
        case "/reset-password":
            return "Reset Password | Travel Nurse 911";
        case "/dashboard":
            return "User Dashboard | Travel Nurse 911";

        // User Routes
        case "/user/jobs":
            return "Jobs | Travel Nurse 911";
        case "/user/short-listed-jobs":
            return "Short Listed Jobs | Travel Nurse 911";
        case path.includes("/user/job/"):
            return "Job Detail | Travel Nurse 911";
        case "/user/applied-jobs":
            return "Applied Jobs | Travel Nurse 911";
        case "/user/calendar":
            return "Calendar | Travel Nurse 911";
        case "/user/messages":
            return "Messages | Travel Nurse 911";
        case "/user/documents":
            return "Documents | Travel Nurse 911";
        case "/user/document-share-history":
            return "Document Share History | Travel Nurse 911";
        case "/user/profile":
            return "Profile | Travel Nurse 911";
        case "/user/checklists":
            return "Checklists | Travel Nurse 911";
        case path.includes("/user/checklist/"):
            return "Checklist Details | Travel Nurse 911";

        // Client Auth Routes
        case "/client/login":
            return currentRole + " Login | Travel Nurse 911";
        case "/client/register":
            return currentRole + " Register | Travel Nurse 911";
        case "/client/email-verification":
            return currentRole + " Email Verification | Travel Nurse 911";
        case "/client/forgot-password":
            return currentRole + " Forgot Password | Travel Nurse 911";
        case "/client/reset-password":
            return currentRole + " Reset Password | Travel Nurse 911";
        case "/client/dashboard":
            return currentRole + " Dashboard | Travel Nurse 911";

        // Client Routes
        case "/client/job-postings":
            return currentRole + " Job Postings | Travel Nurse 911";
        case "/client/job-post-step1":
        case path.includes("/client/job-post-step1/"):
            return "Job Post Step 1 | Travel Nurse 911";
        case "/client/job-post-step2":
        case path.includes("/client/job-post-step2/"):
            return "Job Post Step 2 | Travel Nurse 911";
        case "/client/job-post-step3":
        case path.includes("/client/job-post-step3/"):
            return "Job Post Step 3 | Travel Nurse 911";
        case "/client/job-post-step4":
        case path.includes("/client/job-post-step4/"):
            return "Job Post Step 4 | Travel Nurse 911";
        case "/client/job-post-step5":
        case path.includes("/client/job-post-step5/"):
            return "Job Post Step 5 | Travel Nurse 911";
        case "/client/company-profile":
            return currentRole + " Company Profile | Travel Nurse 911";
        case "/client/job-applications":
            return currentRole + " Job Applications | Travel Nurse 911";
        case path.includes("/client/job/"):
            return currentRole + " Job Detail | Travel Nurse 911";
        case "/client/documents":
            return currentRole + " Documents | Travel Nurse 911";
        case "/client/document-share-history":
            return currentRole + " Document Share History | Travel Nurse 911";
        case "/client/document-types":
            return currentRole + " Document Types | Travel Nurse 911";
        case "/client/clients":
            return currentRole + " Facility Clients | Travel Nurse 911";
        case "/client/assignments":
            return currentRole + " Assignments | Travel Nurse 911";
        case "/client/submissions":
            return currentRole + " Submissions | Travel Nurse 911";
        case "/client/redirects":
            return currentRole + " Redirects | Travel Nurse 911";
        case "/client/compliance-files":
            return currentRole + " Compliance Files | Travel Nurse 911";
        case "/client/compliance-file-types":
            return currentRole + " Compliance File Types | Travel Nurse 911";
        case "/client/compliance-file-categories":
            return currentRole + " Compliance File Categories | Travel Nurse 911";
        case "/client/facility-compliances":
            return currentRole + " Facility Compliances | Travel Nurse 911";
        case "/client/assign-facility-compliance":
            return "Assign Facility Compliance | Travel Nurse 911";
        case "/client/compliance-checklists":
            return "Compliance Checklists | Travel Nurse 911";
        case "/client/add-checklist":
            return "Add Checklist | Travel Nurse 911";
        case "/client/assign-checklist":
            return "Assign Checklist | Travel Nurse 911";
        case "/client/applicants":
            return currentRole + " Applicants | Travel Nurse 911";
        case "/client/employees":
            return currentRole + " Employees | Travel Nurse 911";
        case "/client/interviews":
            return currentRole + " Interviews | Travel Nurse 911";
        case "/client/interviewers":
            return currentRole + " Interviewers | Travel Nurse 911";
        case "/client/candidates":
            return currentRole + " Job Seekers | Travel Nurse 911";
        case "/client/add-candidate-step1":
        case path.includes("/client/add-candidate-step1/"):
            return "Add Candidate Step 1 | Travel Nurse 911";
        case "/client/add-candidate-step2":
            return "Add Candidate Step 2 | Travel Nurse 911";
        case "/client/add-candidate-step3":
            return "Add Candidate Step 3 | Travel Nurse 911";
        case "/client/add-candidate-step4":
            return "Add Candidate Step 4 | Travel Nurse 911";
        case "/client/add-candidate-step5":
            return "Add Candidate Step 5 | Travel Nurse 911";
        case path.includes("/client/user-profile/"):
            return "User Profile | Travel Nurse 911";

        // Admin Routes
        case "/admin/login":
            return "Admin Login | Travel Nurse 911";
        case "/admin/email-verification":
            return "Admin Email Verification | Travel Nurse 911";
        case "/admin/forgot-password":
            return "Admin Forgot Password | Travel Nurse 911";
        case "/admin/reset-password":
            return "Admin Reset Password | Travel Nurse 911";
        case "/admin/dashboard":
            return "Admin Dashboard | Travel Nurse 911";
        case "/admin/job-postings":
            return "Admin Job Postings | Travel Nurse 911";
        case path.includes("/admin/job/"):
            return "Admin Job Detail | Travel Nurse 911";
        case "/admin/job-applications":
            return "Admin Job Applications | Travel Nurse 911";
        case "/admin/interviews":
            return "Admin Interviews | Travel Nurse 911";
        case "/admin/applicants":
            return "Admin Applicants | Travel Nurse 911";
        case "/admin/candidates":
            return "Admin Job Seekers | Travel Nurse 911";
        case "/admin/employees":
            return "Admin Employees | Travel Nurse 911";
        case "/admin/agencies":
            return "Admin Agencies | Travel Nurse 911";
        case path.includes("/admin/agency-profile/"):
            return "Admin Agency Profile | Travel Nurse 911";
        case "/admin/facilities":
            return "Admin Facilities | Travel Nurse 911";
        case "/admin/interviewers":
            return "Admin Interviewers | Travel Nurse 911";
        case "/admin/compliance-files":
            return "Manage Compliance Files | Travel Nurse 911";
        case "/admin/compliance-file-categories":
            return "Compliance Categories | Travel Nurse 911";
        case "/admin/compliance-file-types":
            return "Compliance Types | Travel Nurse 911";
        case "/admin/compliance-list":
            return "Facility Compliance List | Travel Nurse 911";
        case "/admin/documents":
            return "Compliance Documents | Travel Nurse 911";
        case "/admin/document-share-history":
            return "Admin Document Share History | Travel Nurse 911";
        case "/admin/document-types":
            return "Admin Document Types | Travel Nurse 911";
        case "/admin/tasks":
            return "Admin Tasks | Travel Nurse 911";
        case "/admin/company-profile":
            return "Company Profile | Travel Nurse 911";
        case "/admin/sub-admins":
            return "Sub Admins | Travel Nurse 911";
        case "/admin/professions":
            return "Professions | Travel Nurse 911";
        case "/admin/specialities":
            return "Specialities | Travel Nurse 911";
        case "/admin/rec-comp-teams":
            return "Recruitment Teams | Travel Nurse 911";
        case "/admin/reports-analytics":
            return "Reports & Analytics | Travel Nurse 911";
        case "/admin/announcements":
            return "Announcements | Travel Nurse 911";
        case "/admin/scheduling":
            return "Scheduling | Travel Nurse 911";
        case "/admin/clients":
            return "Clients | Travel Nurse 911";
        case "/admin/assignments":
            return "Assignments | Travel Nurse 911";
        case "/admin/submissions":
            return "Submissions | Travel Nurse 911";
        case "/admin/redirects":
            return "Redirects | Travel Nurse 911";

        default:
            return "Travel Nurse 911";
    }
}

export function getRoleById(role_id) {
    switch (role_id) {
        case "3":
            return "Agency";
        case "2":
            return "Facility";
        case "4":
            return "User";
        default:
            return "";
    }
}