import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLogin from "./component/Auth/Admin/Login";
import AdminEmailVerification from "./component/Auth/Admin/EmailVerification";
import AdminForgotPassword from "./component/Auth/Admin/ForgotPassword";
import AdminResetPassword from "./component/Auth/Admin/ResetPassword";
import AdminDashboard from "./component/Admin/AdminDashboard";

import AdminJobs from "./component/Admin/JobSection/AdminJobs";
import AdminJobPostStep1 from "./component/Admin/JobSection/JobPost/JobPostStep1";
import AdminJobPostStep2 from "./component/Admin/JobSection/JobPost/JobPostStep2";
import AdminJobPostStep3 from "./component/Admin/JobSection/JobPost/JobPostStep3";
import AdminJobPostStep4 from "./component/Admin/JobSection/JobPost/JobPostStep4";
import AdminJobPostStep5 from "./component/Admin/JobSection/JobPost/JobPostStep5";
import AdminJobDetail from "./component/Admin/JobSection/AdminJobDetail";
import AdminJobApplocation from "./component/Admin/JobSection/AdminJobApplocation";
import AdminSearchResume from "./component/Admin/JobSection/Search";

import AdminInterviewList from "./component/Admin/HRManagement/Interviews/AdminInterviewList";
import AdminApplicantsList from "./component/Admin/HRManagement/Applicants/AdminApplicantsList";

import AdminCandidatesList from "./component/Admin/HRManagement/Candidates/AdminCandidatesList";
import AdminAddCandidateStep1 from "./component/Admin/HRManagement/Candidates/AddCandidateStep1";
import AdminAddCandidateStep2 from "./component/Admin/HRManagement/Candidates/AddCandidateStep2";
import AdminAddCandidateStep3 from "./component/Admin/HRManagement/Candidates/AddCandidateStep3";
import AdminAddCandidateStep4 from "./component/Admin/HRManagement/Candidates/AddCandidateStep4";
import AdminAddCandidateStep5 from "./component/Admin/HRManagement/Candidates/AddCandidateStep5";

import AdminEmloyeesList from "./component/Admin/HRManagement/Employees/AdminEmloyeesList";
import AdminAddEmployeeStep1 from "./component/Admin/HRManagement/Employees/AddEmployeeStep1";
import AdminAddEmployeeStep2 from "./component/Admin/HRManagement/Employees/AddEmployeeStep2";
import AdminAddEmployeeStep3 from "./component/Admin/HRManagement/Employees/AddEmployeeStep3";
import AdminAddEmployeeStep4 from "./component/Admin/HRManagement/Employees/AddEmployeeStep4";
import AdminAddEmployeeStep5 from "./component/Admin/HRManagement/Employees/AddEmployeeStep5";

import AdminAgenciesList from "./component/Admin/HRManagement/Agencies/AdminAgenciesList";
import AdminAgenciesDetail from "./component/Admin/HRManagement/Agencies/AdminAgenciesDetail";
import AdminEmloyers from "./component/Admin/HRManagement/Employers/AdminEmloyers";
import AdminEmployerDetail from "./component/Admin/HRManagement/Employers/AdminEmployerDetail";
import AdminInterviewers from "./component/Admin/HRManagement/Interviewers/AdminInterviewers";
import ManageCompliance from "./component/Admin/Compliance/ManageCompliance";
import ComplianceCategory from "./component/Admin/Compliance/ComplianceCategory";
import ComplianceTypes from "./component/Admin/Compliance/ComplianceTypes";
import FacilityComplianceLists from "./component/Admin/Compliance/FacilityComplianceLists";

import AdminComplianceChecklists from "./component/Admin/Compliance/ComplianceChecklists";
import AdminComplianceAddChecklist from "./component/Admin/Compliance/ComplianceAddChecklist";
import AdminComplianceSubmittedChecklists from "./component/Admin/Compliance/ComplianceSubmittedChecklist";
import AdminComplianceAssignChecklist from "./component/Admin/Compliance/ComplianceAssignChecklists";

import ComplianceDocument from "./component/Admin/Compliance/ComplianceDocument";
import AdminDocumentShareHistory from "./component/Admin/Compliance/ComplianceDocumentShareHistory";
import AdminiDocumentTypes from "./component/Admin/Compliance/ComplianceDocumentTypes";
import Tasks from "./component/Admin/Tasks/Tasks";
import CompanyProfile from "./component/Admin/AdminSection/CompanyProfile";
import SubAdmin from "./component/Admin/AdminSection/SubAdmin";
import Professions from "./component/Admin/AdminSection/Professions";
import Specialities from "./component/Admin/AdminSection/Specialities";
import RecruitmentTeam from "./component/Admin/AdminSection/RecruitmentTeam";
import ReportsAnalytics from "./component/Admin/AdminSection/ReportsAnalytics";
import Announcements from "./component/Admin/AdminSection/Announcements";
import Skills from "./component/Admin/AdminSection/Skills";
import Scheduling from "./component/Admin/Scheduling/Scheduling";
import Clients from "./component/Admin/AccountManagement/Clients";
import Assignments from "./component/Admin/AccountManagement/Assignments";
import Submissions from "./component/Admin/AccountManagement/Submissions";
import Redirects from "./component/Admin/AccountManagement/Redirects";

import AdminSMS from "./component/Admin/OtherSection/AdminSMS";
import AdminEmail from "./component/Admin/OtherSection/AdminEmail";
import UserProfile from "./component/Admin/HRManagement/UserProfile/UserProfile";
import ActivityLog from "./component/Admin/OtherSection/ActivityLog";
import AdminContactEnquiries from "./component/Admin/OtherSection/AdminContactEnquiries";
import AdminPilotPartnerApplications from "./component/Admin/OtherSection/AdminPilotPartnerApplications";
import AdminCalender from "./component/Admin/AdminCalender";
import AdminBlogs from "./component/Admin/OtherSection/AdminBlogs";
import Subscription from "./component/Admin/Subscriptions";

function AdminApp() {
    return (
        <Routes>
            <Route path="admin">
                <Route exact path="login" Component={AdminLogin}></Route>
                <Route exact path="email-verification" Component={AdminEmailVerification}></Route>
                <Route exact path="forgot-password" Component={AdminForgotPassword}></Route>
                <Route exact path="reset-password" Component={AdminResetPassword}></Route>

                <Route exact path="dashboard" Component={AdminDashboard}></Route>
                <Route exact path="calender" Component={AdminCalender}></Route>

                <Route exact path="job-postings" Component={AdminJobs}></Route>
                <Route exact path="boosted-jobs" Component={AdminJobs}></Route>
                <Route exact path="job-post-step1" Component={AdminJobPostStep1}></Route>
                <Route exact path="job-post-step1/:jobID" Component={AdminJobPostStep1}></Route>
                <Route exact path="job-post-step2" Component={AdminJobPostStep2}></Route>
                <Route exact path="job-post-step2/:jobID" Component={AdminJobPostStep2}></Route>
                <Route exact path="job-post-step3" Component={AdminJobPostStep3}></Route>
                <Route exact path="job-post-step3/:jobID" Component={AdminJobPostStep3}></Route>
                <Route exact path="job-post-step4" Component={AdminJobPostStep4}></Route>
                <Route exact path="job-post-step4/:jobID" Component={AdminJobPostStep4}></Route>
                <Route exact path="job-post-step5" Component={AdminJobPostStep5}></Route>
                <Route exact path="job-post-step5/:jobID" Component={AdminJobPostStep5}></Route>
                <Route exact path="job/:jobId" Component={AdminJobDetail}></Route>
                <Route exact path="job-applications" Component={AdminJobApplocation}></Route>
                <Route exact path="search" Component={AdminSearchResume}></Route>

                <Route exact path="interviews" Component={AdminInterviewList}></Route>
                <Route exact path="follow-ups" Component={AdminInterviewList}></Route>
                <Route exact path="applicants" Component={AdminApplicantsList}></Route>

                <Route exact path="candidates" Component={AdminCandidatesList}></Route>
                <Route exact path="add-candidate-step1" Component={AdminAddCandidateStep1}></Route>

                <Route
                    exact
                    path="add-candidate-step1/:userID"
                    Component={AdminAddCandidateStep1}
                ></Route>
                <Route exact path="add-candidate-step2" Component={AdminAddCandidateStep2}></Route>
                <Route exact path="add-candidate-step3" Component={AdminAddCandidateStep3}></Route>
                <Route exact path="add-candidate-step4" Component={AdminAddCandidateStep4}></Route>
                <Route exact path="add-candidate-step5" Component={AdminAddCandidateStep5}></Route>

                <Route exact path="employees" Component={AdminEmloyeesList}></Route>
                <Route exact path="add-employee-step1" Component={AdminAddEmployeeStep1}></Route>
                <Route exact path="add-employee-step1/:userID" Component={AdminAddEmployeeStep1}></Route>
                <Route exact path="add-employee-step2" Component={AdminAddEmployeeStep2}></Route>
                <Route exact path="add-employee-step3" Component={AdminAddEmployeeStep3}></Route>
                <Route exact path="add-employee-step4" Component={AdminAddEmployeeStep4}></Route>
                <Route exact path="add-employee-step5" Component={AdminAddEmployeeStep5}></Route>

                <Route exact path="agencies" Component={AdminAgenciesList}></Route>
                <Route exact path="agency-profile/:Id" Component={AdminAgenciesDetail}></Route>

                <Route exact path="facilities" Component={AdminEmloyers}></Route>
                <Route exact path="facility-profile/:Id" Component={AdminEmployerDetail}></Route>

                <Route exact path="office-admins" Component={AdminInterviewers}></Route>
                <Route exact path="compliance-files" Component={ManageCompliance}></Route>
                <Route exact path="compliance-file-categories" Component={ComplianceCategory}></Route>
                <Route exact path="compliance-file-types" Component={ComplianceTypes}></Route>
                <Route exact path="compliance-list" Component={FacilityComplianceLists}></Route>

                <Route exact path="compliance-checklists" Component={AdminComplianceChecklists}></Route>
                <Route
                    exact
                    path="completed-checklists"
                    Component={AdminComplianceSubmittedChecklists}
                ></Route>
                <Route exact path="add-checklist" Component={AdminComplianceAddChecklist}></Route>
                <Route
                    exact
                    path="add-checklist/:checklistID"
                    Component={AdminComplianceAddChecklist}
                ></Route>
                <Route exact path="assign-checklist" Component={AdminComplianceAssignChecklist}></Route>

                <Route exact path="documents" Component={ComplianceDocument}></Route>
                <Route exact path="document-share-history" Component={AdminDocumentShareHistory}></Route>
                <Route exact path="document-types" Component={AdminiDocumentTypes}></Route>
                <Route exact path="tasks" Component={Tasks}></Route>
                <Route exact path="company-profile" Component={CompanyProfile}></Route>
                <Route exact path="sub-admins" Component={SubAdmin}></Route>
                <Route exact path="professions" Component={Professions}></Route>
                <Route exact path="specialities" Component={Specialities}></Route>
                <Route exact path="rec-comp-teams" Component={RecruitmentTeam}></Route>
                <Route exact path="reports-analytics" Component={ReportsAnalytics}></Route>
                <Route exact path="announcements" Component={Announcements}></Route>
                <Route exact path="skills" Component={Skills}></Route>
                <Route exact path="scheduling" Component={Scheduling}></Route>

                <Route exact path="clients" Component={Clients}></Route>
                <Route exact path="assignments" Component={Assignments}></Route>
                <Route exact path="submissions" Component={Submissions}></Route>
                <Route exact path="redirects" Component={Redirects}></Route>
                <Route exact path="user-profile/:userUniqueID" Component={UserProfile}></Route>

                <Route exact path="blogs" Component={AdminBlogs}></Route>

                <Route exact path="sms" Component={AdminSMS}></Route>
                <Route exact path="email" Component={AdminEmail}></Route>
                <Route exact path="activity-log" Component={ActivityLog}></Route>
                <Route exact path="contact-enquiries" Component={AdminContactEnquiries}></Route>
                <Route exact path="subscriptions" Component={Subscription}></Route>

                <Route
                    exact
                    path="pilot-partner-applications"
                    Component={AdminPilotPartnerApplications}
                ></Route>
            </Route>
        </Routes>
    )
}

export default AdminApp;