import { useNavigate } from "react-router-dom";

export const GetSubscriptionCTAButton = ({ plan }) => {
    const navigate = useNavigate();
    return (
        <div className="card-footer text-center pt-0 mt-2">
            <button
                onClick={() =>
                    navigate('/client/checkout', {
                        state: plan,
                    })
                }
                className="btn btn-primary waves-effect waves-light width-sm"
                style={{
                    background: 'rgb(30, 136, 242)',
                    border: 'none',
                }}
            >
                Get Subscription
            </button>
        </div>
    )
};