// Billing cycle map for reusable mappings
export const billingCycleMap = {
    'trial': 'Trial',
    'one_time': 'Pay as you go',
    'monthly': 'Month',
    'annually': 'Year'
};

export const billingCycleMap2 = {
    'trial': 'Trial',
    'one_time': 'Pay as you go',
    'monthly': 'Monthly',
    'annually': 'Yearly'
};

/**
 * Get the label for a billing cycle.
 * @param {string} billingCycleType - The billing cycle key (e.g., 'one_time', 'monthly', 'annually').
 * @returns {string} - The corresponding label or 'Unknown' if the key is not found.
 */
export const getBillingCycleLabel = (billingCycleType, varient = billingCycleMap, isTrial = false) => {
    billingCycleType = isTrial ? 'trial' : billingCycleType;
    return varient[billingCycleType] || 'Unknown';
};