import useSubscriptionStore from "../store/useSubscriptionStore";

const useSubscription = () => {
  // Access Zustand store states and methods
  const { data: subscription = null, fetch: fetchSubscription, loading, error } = useSubscriptionStore();
  const { plan = null } = subscription || {};
  const { data = null } = plan || {};

  // Module Guard: Check if a module is enabled
  const isModuleEnabled = (moduleName) => {
    if (!data) return false;
    return data.some((module) => module.module_name === moduleName);
  };

  // Feature Guard: Check if a feature is enabled within a module
  const isFeatureEnabledForModule = (moduleName, featureName) => {
    if (!data) return false;

    const _module = data.find((mod) => mod.module_name === moduleName);
    if (!_module) return false;

    const feature = _module.features.find((feat) => feat.name === featureName && feat.is_enabled === 1);
    return !!feature;
  };

  return { subscription, isModuleEnabled, isFeatureEnabledForModule, fetchSubscription, loading, error };
};

export default useSubscription;