import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import { getBillingCycleLabel } from '../../../../utils/billingCycleHelper';
export const MyPlanComponent = ({ subscription = {}, children }) => {
    const { billing_cycle = null, plan = null } = subscription;
    const { data = null } = plan || {};
    const billingCycleLabel = getBillingCycleLabel(billing_cycle.type, undefined, plan.type === 'trial');
    return (
        <div className="card custom-box-shadow card-pricing">
            <div className="card-body text-center pb-0">
                <p className="card-pricing-plan-name font-weight-bold text-uppercase fw-bold">
                    {subscription.plan.name} Plan
                </p>
                <span className="card-pricing-icon text-primary">
                    <i className="fas fa-podcast" />
                </span>
                <h2
                    className="card-pricing-price"
                    style={{
                        borderTop: '3px solid #efeefc',
                        color: '#8686e0',
                        fontSize: '26px',
                        fontWeight: 700,
                    }}
                >
                    ${subscription.billing_cycle.price}{' '}
                    <span style={{ color: '#8686e0' }}>/ {billingCycleLabel}</span>
                </h2>
                {data && data.map((_module) => (
                    <MDBAccordion flush key={_module.module_id}>
                        <MDBAccordionItem
                            collapseId={_module.module_id}
                            headerTitle={
                                <span
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 700,
                                        color: '#ff5712',
                                    }}
                                >
                                    {_module.module_name}
                                </span>
                            }
                        >
                            {_module.features.map((feature) => (
                                <p
                                    key={feature.id}
                                    style={{ fontWeight: '700', fontSize: '13px' }}
                                >
                                    {feature.name}
                                </p>
                            ))}
                        </MDBAccordionItem>
                    </MDBAccordion>
                ))}
            </div>
            {children}
        </div>
    );
};