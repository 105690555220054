import "jquery-ui-dist/jquery-ui";

import { Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";

/* Client Auth Compnents */
import ClientLogin from "./component/Auth/Client/Login";
import ClientRegister from "./component/Auth/Client/Register";
import ClientEmailVerification from "./component/Auth/Client/EmailVerification";
import ClientForgotPassword from "./component/Auth/Client/ForgotPassword";
import ClientResetPassword from "./component/Auth/Client/ResetPassword";

/* Client Compnents */
import ClientDashboard from "./component/Client/Dashboard";
import ClientInbox from "./component/Client/Messages";
import ClientCalendar from "./component/Client/Calendar";

import ClientJobs from "./component/Client/Job/Jobs";
import ClientJobPostStep1 from "./component/Client/Job/JobPostStep1";
import ClientJobPostStep2 from "./component/Client/Job/JobPostStep2";
import ClientJobPostStep3 from "./component/Client/Job/JobPostStep3";
import ClientJobPostStep4 from "./component/Client/Job/JobPostStep4";
import ClientJobPostStep5 from "./component/Client/Job/JobPostStep5";
import ClientJobApplications from "./component/Client/Job/JobApplications";
import ClientJobDetail from "./component/Client/Job/JobDetail";
import ClientSearch from "./component/Client/Job/Search";

import ClientDocuments from "./component/Client/Document/Documents";
import ClientDocumentShareHistory from "./component/Client/Document/DocumentShareHistory";
import ClientDocumentTypes from "./component/Client/Document/DocumentTypes";

import ClientFacilityClients from "./component/Client/AccountManagement/ManageClients";
import ClientAssignments from "./component/Client/AccountManagement/ManageAssignments";
import ClientSubmissions from "./component/Client/AccountManagement/ManageSubmissions";
import ClientRedirects from "./component/Client/AccountManagement/ManageRedirects";

import ClientComplianceFiles from "./component/Client/ComplianceFiles/ComplianceFiles";
import ClientComplianceTypes from "./component/Client/ComplianceFiles/ComplianceTypes";
import ClientComplianceCategories from "./component/Client/ComplianceFiles/ClientComplianceCategories";

import ClientFacilityCompliances from "./component/Client/ComplianceFiles/ClientFacilityCompliances";
import ClientAssignFacilityCompliances from "./component/Client/ComplianceFiles/AssignFacilityCompliances";

import ClientComplianceChecklists from "./component/Client/ComplianceFiles/ComplianceChecklists";
import ClientComplianceAddChecklist from "./component/Client/ComplianceFiles/ComplianceAddChecklist";
import ClientAssignChecklist from "./component/Client/ComplianceFiles/AssignChecklist";
import ClientSubmittedChecklists from "./component/Client/ComplianceFiles/SubmittedChecklist";

import ClientProfile from "./component/Client/Profile/Profile";
import ClietScheduling from "./component/Client/Scheduling/Scheduling";
import ClientApplicants from "./component/Client/Applicants/ManageApplicants";

import ClientCandidates from "./component/Client/Candidates/ManageCandidates";
import ClientAddCandidateStep1 from "./component/Client/Candidates/AddCandidateStep1";
import ClientAddCandidateStep2 from "./component/Client/Candidates/AddCandidateStep2";
import ClientAddCandidateStep3 from "./component/Client/Candidates/AddCandidateStep3";
import ClientAddCandidateStep4 from "./component/Client/Candidates/AddCandidateStep4";
import ClientAddCandidateStep5 from "./component/Client/Candidates/AddCandidateStep5";
import ClientEmployees from "./component/Client/Employees/ManageEmployees";
import ClientAddEmployeeStep1 from "./component/Client/Employees/AddEmployeeStep1";
import ClientAddEmployeeStep2 from "./component/Client/Employees/AddEmployeeStep2";
import ClientAddEmployeeStep3 from "./component/Client/Employees/AddEmployeeStep3";
import ClientAddEmployeeStep4 from "./component/Client/Employees/AddEmployeeStep4";
import ClientAddEmployeeStep5 from "./component/Client/Employees/AddEmployeeStep5";
import ClientOfficeAdmins from "./component/Client/OfficeAdmins/OfficeAdmins";
import ClientInterviews from "./component/Client/Interviews/ManageInterviews";
import ClientTasks from "./component/Client/Tasks/Tasks";
import ClientCheckout from "./component/Client/SubscriptionPlans/Checkout";
import ClientSMS from "./component/Client/OtherSection/SMS";
import ClientEmail from "./component/Client/OtherSection/Email";
import SubscriptionPlanList from "./component/Client/SubscriptionPlans/SubscriptionPlanList";
import SubscriptionDetails from "./component/Client/SubscriptionPlans/SubscriptionDetails";
import jobRequest from "./component/Client/Job_Request/Job_Request";
import JobUserDetails from "./component/Client/Job_Request/UserDetails/UserDetails";
import Client_Post_Job_Request_Step1 from "./component/Client/Job_Request/UserDetails/Create Opportunity/Post_Job_Request_Step1";
import Client_Post_Job_Request_Step2 from "./component/Client/Job_Request/UserDetails/Create Opportunity/Post_Job_Request_Step2";
import useSubscription from "./hooks/useSubscription";
import usePlanStore from "./store/usePlanStore";
import PublicRoute from "./component/Client/components/routes/PublicRoute";
import PrivateRoute from "./component/Client/components/routes/PrivateRoute";
import useAuth from "./hooks/useAuth";
import Spinner from "./component/Loader/Spinner";

const FallbackComponent = () => {
  return (
    <>
      <div>
        <p>Opps you are not allowed to use this feature</p>
      </div>
    </>
  )
}

const AuthorizedClientApp = () => {
  const {isAuthenticated} = useAuth();
  const {fetch: fetchPlans, loading: loadingPlans } = usePlanStore();
  const { isModuleEnabled, isFeatureEnabledForModule, loading: loadingSubscription, fetchSubscription } = useSubscription();

  useEffect(() => {
    if(isAuthenticated){
      fetchSubscription(); // Fetch subscription on component mount
      fetchPlans(); // Fetch plans on component mount
    }
  }, [isAuthenticated, fetchSubscription, fetchPlans]);

  const routesConfig = [
    { path: "dashboard", component: ClientDashboard },
    {
      module: "Recruitment Tools",
      features: [
        { feature: "Job Posting Slots", routes: [
          { path: "job-postings", component: ClientJobs },
          { path: "boosted-jobs", component: ClientJobs },
          { path: "job-post-step1", component: ClientJobPostStep1 },
          { path: "job-post-step1/:jobID", component: ClientJobPostStep1 },
          { path: "job-post-step2", component: ClientJobPostStep2 },
          { path: "job-post-step2/:jobID", component: ClientJobPostStep2 },
          { path: "job-post-step3", component: ClientJobPostStep3 },
          { path: "job-post-step3/:jobID", component: ClientJobPostStep3 },
          { path: "job-post-step4", component: ClientJobPostStep4 },
          { path: "job-post-step4/:jobID", component: ClientJobPostStep4 },
          { path: "job-post-step5", component: ClientJobPostStep5 },
          { path: "job-post-step5/:jobID", component: ClientJobPostStep5 },
        ]},
        { feature: "Applicant Tracking", routes: [
          { path: "job-applications", component: ClientJobApplications },
          { path: "applicants", component: ClientApplicants },
        ]},
        { feature: "Follow Up Management", routes: [
          { path: "follow-ups", component: ClientInterviews },
        ]},
      ],
    },
    {
      module: "Compliance File Tools",
      features: [
        { feature: "Manage Employee Compliance Files", routes: [
          { path: "compliance-files", component: ClientComplianceFiles },
          { path: "compliance-file-types", component: ClientComplianceTypes },
          { path: "compliance-file-categories", component: ClientComplianceCategories },
          { path: "facility-compliances", component: ClientFacilityCompliances },
          { path: "assign-facility-compliance", component: ClientAssignFacilityCompliances },
        ]},
        { feature: "Skills Checklists", routes: [
          { path: "compliance-checklists", component: ClientComplianceChecklists },
          { path: "completed-checklists", component: ClientSubmittedChecklists },
          { path: "add-checklist", component: ClientComplianceAddChecklist },
          { path: "add-checklist/:checklistID", component: ClientComplianceAddChecklist },
          { path: "assign-checklist", component: ClientAssignChecklist },
        ]},
      ],
    },
    {
      module: "Administration & Management Tools",
      features: [
        {
          feature: "Task Management",
          routes: [
            { path: "tasks", component: ClientTasks },
            { path: "office-admins", component: ClientOfficeAdmins },
          ],
        },
        {
          feature: "Calendar & Reminders",
          routes: [
            { path: "scheduling", component: ClietScheduling },
            { path: "calendar", component: ClientCalendar },
          ],
        },
        {
          feature: "Messaging/Inbox",
          routes: [
            { path: "inbox", component: ClientInbox },
            { path: "sms", component: ClientSMS },
            { path: "email", component: ClientEmail },
          ]
        },
        {
          feature: "Add Admin Users",
          routes: [
            { path: "office-admins", component: ClientOfficeAdmins },
          ]
        }
      ]
    },
    { path: "search", component: ClientSearch },
    { path: "shared-documents", component: ClientDocuments },
    { path: "document-share-history", component: ClientDocumentShareHistory },
    { path: "document-types", component: ClientDocumentTypes },
    { path: "clients", component: ClientFacilityClients },
    { path: "assignments", component: ClientAssignments }, // need to add this
    { path: "submissions", component: ClientSubmissions }, // need to add this
    { path: "redirects", component: ClientRedirects },
    // { path: "interviews", component: ClientInterviews }, // removed
    
    { path: "employees", component: ClientEmployees },
    { path: "add-employee-step1", component: ClientAddEmployeeStep1 },
    { path: "add-employee-step1/:userID", component: ClientAddEmployeeStep1 },
    { path: "add-employee-step2", component: ClientAddEmployeeStep2 },
    { path: "add-employee-step3", component: ClientAddEmployeeStep3 },
    { path: "add-employee-step4", component: ClientAddEmployeeStep4 },
    { path: "add-employee-step5", component: ClientAddEmployeeStep5 },
    
    { path: "candidates", component: ClientCandidates },
    { path: "add-candidate-step1", component: ClientAddCandidateStep1 },
    { path: "add-candidate-step1/:userID", component: ClientAddCandidateStep1 },
    { path: "add-candidate-step2", component: ClientAddCandidateStep2 },
    { path: "add-candidate-step3", component: ClientAddCandidateStep3 },
    { path: "add-candidate-step4", component: ClientAddCandidateStep4 },
    { path: "add-candidate-step5", component: ClientAddCandidateStep5 },
    
    { path: "job-request", component: jobRequest },
    { path: "job-request/detail", component: JobUserDetails },
    { path: "job/:jobId", component: ClientJobDetail },
    { path: "post_job_request", component: Client_Post_Job_Request_Step1 },
    { path: "post_job_request2", component: Client_Post_Job_Request_Step2 },
  
    { path: "company-profile", component: ClientProfile },
    { path: "subscription-plans", component: SubscriptionPlanList },
    { path: "my-subscription", component: SubscriptionDetails },
    { path: "checkout", component: ClientCheckout },
  ];  

  if (loadingPlans && loadingSubscription) return (<Spinner />);
  // if (plansError) return <div>Error: {plansError}</div>;
  // if (subscriptionError) return <div>Error: {subscriptionError}</div>;

  const renderRoutes = (routes) =>
    routes.flatMap(({ path, component, module, features }) => {
      if (module && !isModuleEnabled(module)) return [];
      if (features) {
        return features.flatMap(({ feature, routes }) =>
          isFeatureEnabledForModule(module, feature) ? renderRoutes(routes) : []
        );
      }
      return <Route key={path} exact path={`/client/${path}`} element={<PrivateRoute Component={component} />} />;
  });

  return (
    <Routes>
      {/* === Client Routes === */}
      {renderRoutes(routesConfig)}  {/* Dynamically render routes */}
      {/* Default fallback */}
      <Route path="*" element={<FallbackComponent />} />
    </Routes>
  )
}

function ClientApp() {
  return (
    <>
      <Routes>
        {/* === Client Auth Routes === */}
        <Route exact path="/client/login" element={<PublicRoute Component={ClientLogin} />}></Route>
        <Route exact path="/pilot-program/signup" Component={ClientRegister}></Route>
        <Route exact path="/client/email-verification" element={<PublicRoute Component={ClientEmailVerification} />}></Route>
        <Route exact path="/client/forgot-password" element={<PublicRoute Component={ClientForgotPassword} />}></Route>
        <Route exact path="/client/reset-password" element={<PublicRoute Component={ClientResetPassword} />}></Route>
        <Route exact path="/staff/login" element={<PublicRoute Component={ClientLogin} />}></Route>
      </Routes>
      {/* === Client Auth Routes === */}
      <AuthorizedClientApp />
    </>
  );
}

export default ClientApp;