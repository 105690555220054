import { useMemo } from "react";
import usePlanStore from "../../../../store/usePlanStore";
import { PlanComponent } from "./PlanComponent";
import { GetSubscriptionCTAButton } from "./GetSubscriptionCTAButton";
import Spinner from "../../../Loader/Spinner";

export const PlanListComponent = () => {
    const { data: plans, loading } = usePlanStore();

    const filteredPlans = useMemo(() => {
        if (!plans) return [];
        return plans.filter(plan => plan.plan_type !== 'trial')
    }, [plans])

    if (loading) return <Spinner />;
    return (
        <div className="row">
            {filteredPlans.map((plan, index) => (
                <PlanComponent key={index} plan={plan}>
                    <GetSubscriptionCTAButton plan={plan} />
                </PlanComponent>
            ))}
        </div>
    );
}