import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../Layout/Index'
import { currentRole } from "../../Layout/HelmetComponent";
import PageTitle from "../../Layout/PageTitle";
import useSubscriptionStore from '../../../store/useSubscriptionStore';
import { MyPlanComponent } from './components/MyPlanComponent';
import SubscriptionStatusComponent from './components/SubscriptionStatusComponent';
import SubscriptionHistoryComponent from './components/SubscriptionHistoryComponent';



const SubscriptionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Subscription Details", link: null },
  ];
  const { data: subscription, refetch: refetchSubscription } = useSubscriptionStore();
  const [refetchTriggered, setRefetchTriggered] = useState(location?.state?.refetch);

  useEffect(() => {
    const handleRefetch = () => {
      if (refetchTriggered) {
        refetchSubscription();
        setRefetchTriggered(false);
      }
    };

    // const redirectIfNoSubscription = () => {
    //   if (!subscription) {
    //     navigate('/client/subscription-plans');
    //   }
    // };

    handleRefetch();
    //redirectIfNoSubscription();
  }, [refetchTriggered, navigate, refetchSubscription, subscription]);

  return (
    <Layout>
      <PageTitle
        title="Subscription Details"
        breadcrumbItems={breadcrumbItems}
      />
      <div className='d-flex flex-row'>
        {subscription && (
          <div className="col-md-4 p-2">
            <div className='d-flex flex-column'>
              {subscription && subscription.status === "active" && (
                <>
                  <div className="mb-3">
                    <SubscriptionStatusComponent subscribedPlan={subscription} />
                  </div>
                  <MyPlanComponent
                    subscription={subscription}
                  />
                </>
              )}
            </div>
          </div>
        )}
        <div className="col-md-8 p-2">
          <SubscriptionHistoryComponent />
        </div>
      </div>
    </Layout>
  )
}

export default SubscriptionDetails
