import { useRemainingDays } from "../../../../hooks/my-subscription";

const SubscriptionRemainingDays = ({subscribedPlan}) => {
    const { label, remainingDays } = useRemainingDays({ subscribedPlan });
    // If label is invalid or remainingDays is not a number, return null
    if (!label || isNaN(remainingDays)) {
      return null; // Don't render the card if dates are invalid or improperly configured
    }
    return (
      <div className="col-3">
        <div className="avatar-lg rounded-circle bg-soft-primary border-primary border">
          <p className="font-18 avatar-title text-primary">
            {remainingDays > 0 ? remainingDays : 'Expired'}
          </p>
        </div>
        <p>{remainingDays > 0 ? 'Days Remaining' : 'Subscription Expired'}</p>
      </div>
    )
  }

export default SubscriptionRemainingDays;