import "jquery-ui-dist/jquery-ui";
import React from "react";
import HelmetComponent from "./component/Layout/HelmetComponent";
import useAppType from "./hooks/useAppType";
import UserApp from "./UserApp";
import ClientApp from "./ClientApp";
import AdminApp from "./AdminApp";

function App() {
  const appType = useAppType();
  return (
    <div>
        <HelmetComponent />      
        {appType === "user" && <UserApp />} 
        {appType === "client" && <ClientApp />} 
        {appType === "admin" && <AdminApp />} 
    </div>
  );
}

export default App;