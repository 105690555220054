import React from "react";
import Layout from "../../Layout/Index";
import PageTitle from "../../Layout/PageTitle";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { currentRole } from "../../Layout/HelmetComponent";
import { PlanListComponent } from "./components/PlanListComponent.";

function SubscriptionPlanList() {
  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Subscription Plans", link: null },
  ];
  return (
    <Layout>
      <div className="container-fluid">
        {/* start page title */}
        <PageTitle
          title="Subscription Plans"
          breadcrumbItems={breadcrumbItems}
        />
        {/* end page title */}
        <div className="row mb-3">
          <PlanListComponent />
        </div>
      </div>
    </Layout>
  );
}

export default SubscriptionPlanList;
