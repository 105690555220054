
import useSubscriptionStore from "../store/useSubscriptionStore";
import { apiRequest } from "../services/ApiRequest";
import { toast } from "react-toastify";
import { useCallback, useRef   } from "react";

const useSubscriptionCancel = () => {
  const isExecuting = useRef(false);
  const { data: subscription = null, fetch: fetchSubscription } = useSubscriptionStore();
  const execute = useCallback(async() => {
    if (isExecuting.current) {
      console.warn("Cancellation is already in progress.");
      return;
    }
    if (!subscription || !subscription.paypal_ref_id) {
      toast.warn("No valid subscription found to cancel.");
      return;
    }

    isExecuting.current = true;

    try {
      const response = await apiRequest("client/subscription/cancel", "POST");
      console.log(response);
      if (response.success) {
        toast.info(response.message);        
        fetchSubscription();
      }

      if (response.success === false) {
        toast.error(response.error);        
        fetchSubscription();
      }
    } catch (err) {
      console.error("Cancel subscription error:", err);
    } finally {
      isExecuting.current = false;
    }
  }, [fetchSubscription, subscription]);

  return { execute };
};

export default useSubscriptionCancel;