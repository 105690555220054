import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import { getBillingCycleLabel } from '../../../../utils/billingCycleHelper';
export const PlanComponent = ({ plan, children }) => {
    const billingCycle = plan.billing_cycles[0];
    const billingCycleLabel = getBillingCycleLabel(billingCycle.type);
    return (
        <div className="col-md-3">
            <div className="card card-pricing">
                <div className="card-body text-center pb-0">
                    <p className="card-pricing-plan-name font-weight-bold text-uppercase fw-bold">
                        {plan.plan_name} Plan
                    </p>
                    <span className="card-pricing-icon text-primary">
                        <i className="fas fa-podcast" />
                    </span>
                    <h2
                        className="card-pricing-price"
                        style={{
                            borderTop: '3px solid #efeefc',
                            color: '#8686e0',
                            fontSize: '26px',
                            fontWeight: 700,
                        }}
                    >
                        ${plan.billing_cycles[0].price}{' '}
                        <span style={{ color: '#8686e0' }}>/ {billingCycleLabel}</span>
                    </h2>
                    {plan.data.map((module) => (
                        <MDBAccordion flush key={module.module_id}>
                            <MDBAccordionItem
                                collapseId={module.module_id}
                                headerTitle={
                                    <span
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: 700,
                                            color: '#ff5712',
                                        }}
                                    >
                                        {module.module_name}
                                    </span>
                                }
                            >
                                {module.features.map((feature) => (
                                    <p
                                        key={feature.id}
                                        style={{ fontWeight: '700', fontSize: '13px' }}
                                    >
                                        {feature.name}
                                    </p>
                                ))}
                            </MDBAccordionItem>
                        </MDBAccordion>
                    ))}
                </div>
                {children}
            </div>
        </div>
    );
};