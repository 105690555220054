import { toast } from "react-toastify";

export async function apiRequest(endpoint, method = "GET", data = null) {
    const token = localStorage.getItem("token");
    let url = process.env.REACT_APP_API_URL + endpoint;

    try {
        // Handle query parameters for GET requests
        if (method.toUpperCase() === "GET" && data) {
            const queryString = new URLSearchParams(data).toString();
            url += `?${queryString}`;
        }

        const options = {
            method: method.toUpperCase(),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        // Add the body only for methods that support it
        if (data && ["POST", "PUT", "PATCH"].includes(method.toUpperCase())) {
            options.body = JSON.stringify(data);
        }

        const response = await fetch(url, options);

        // // Check for network-level errors
        // if (!response.ok) {
        //     console.log(response)
        //     const errorMessage = response.error ?? `Error ${response.status}: ${response.statusText}`;
        //     return {
        //         success: false,
        //         status: response.status,
        //         error: errorMessage,
        //         data: null,
        //     };
        // }

        const res = await response.json();

        console.log(res);

        return {
            success: res.success,
            status: response.status,
            error: res.success ? "Request successful" : res.error || "An error occurred",
            data: res.success ? res.data : null,
        };
    } catch (error) {
        // Handle unexpected errors
        console.error("Unexpected Error:", error);
        toast.error("Unexpected error occurred. Please try again later.");
        return {
            success: false,
            status: null,
            error: "Unexpected error occurred. Please try again later.",
            data: null,
        };
    }
}
