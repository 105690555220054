import useSubscriptionCancel from "../../../../hooks/useSubscriptionCancel";

const SubscriptionCancelAction = () => {
    const { execute } = useSubscriptionCancel();

    return (
        <div className="col-3">
            {execute !== undefined && (
                <button
                    onClick={execute}
                    className="btn btn-danger text-white px-2 py-1 rounded"
                >
                    Cancel
                </button>
            )}
        </div>
    );
};

export default SubscriptionCancelAction;