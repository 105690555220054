import SubscriptionRemainingDays from "./SubscriptionRemainingDays";
import SubscriptionRemainingJobPostCount from "./SubscriptionRemainingJobPostCount";

const SubscriptionStatusComponent = ({ subscribedPlan }) => {
  return (
    <div className="card-body custom-box-shadow p-2">
      <div className="row">
        <SubscriptionRemainingDays subscribedPlan={subscribedPlan} />
        <SubscriptionRemainingJobPostCount subscribedPlan={subscribedPlan} />
      </div>
    </div>
  );
};

export default SubscriptionStatusComponent;