import { useLocation } from 'react-router-dom';

export default function useAppType() {
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname.startsWith('/client/')) {
    return 'client';
  } else if (pathname.startsWith('/admin/')) {
    return 'admin';
  } else {
    return 'user';
  }
}