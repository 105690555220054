import { useMemo } from 'react';

const useRemainingJobSlots = (subscribedPlan) => {
  return useMemo(() => {
    if (!subscribedPlan || !subscribedPlan.job_posts) {
      return {
        canPost: false,
        availableCount: 0,
        canPostUnlimited: false,
      };
    }

    const { canPost, canPostUnlimited, availableCount } = subscribedPlan.job_posts;

    return {
      canPost,
      canPostUnlimited,
      availableCount: canPostUnlimited ? "Unlimited" : availableCount,
      label: canPost ? (canPostUnlimited ? "Job Post" : "Job Slot Remaining") : "No Job Slot Remaining",
    };
  }, [subscribedPlan]);
};

export default useRemainingJobSlots;