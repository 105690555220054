import { useState } from "react";
import { Routes, Route } from "react-router-dom";

/* User Auth Compnents */
import Login from "./component/Auth/User/Login";
import Register from "./component/Auth/User/Register";
import EmailVerification from "./component/Auth/User/EmailVerification";
import ForgotPassword from "./component/Auth/User/ForgotPassword";
import ResetPassword from "./component/Auth/User/ResetPassword";

/* User Compnents */
import Dashboard from "./component/User/Dashboard";
import Jobs from "./component/User/Job/Jobs";
import ShortListedJobs from "./component/User/Job/ShortListedJobs";
import JobDetail from "./component/User/Job/JobDetail";
import UserCompanyProfile from "./component/User/Job/CompanyProfile";
import AppliedJobs from "./component/User/Job/AppliedJobs";
import Calendar from "./component/User/Calendar";
import Messages from "./component/User/Messages";
import Documents from "./component/User/Document/Documents";
import DocumentShareHistory from "./component/User/Document/DocumentShareHistory";
import DocumentAccessRequests from "./component/User/Document/DocumentAccessRequests";
import ComplianceLists from "./component/User/ComplianceLists";
import Profile from "./component/User/Profile/Profile";
import Checklists from "./component/User/Checklist/Checklists";
import ChecklistDetails from "./component/User/Checklist/ChecklistDetails";
import Checkout from "./component/User/SubscriptionPlans/Checkout";
import Settings from "./component/User/Settings";

import JobRequset from "./component/User/Job _Request/JobRequset";
import AddJobRequestStep1 from "./component/User/Job _Request/Tabs/Job-request-step1";
import JobRequestStep2 from "./component/User/Job _Request/Tabs/Job-request-step2";
import JobRequestStep3 from "./component/User/Job _Request/Tabs/Job-request-step3";

function UserApp() {
    const [key, setKey] = useState(0);
    const refreshPage = () => setKey((prevKey) => prevKey + 1);
    return (
        <div key={key}>
            <Routes>
                {/* === User Auth Routes === */}
                <Route exact path="/" Component={Login}></Route>
                <Route exact path="/register" Component={Register}></Route>
                <Route exact path="/email-verification" Component={EmailVerification}></Route>
                <Route exact path="/forgot-password" Component={ForgotPassword}></Route>
                <Route exact path="/reset-password" Component={ResetPassword}></Route>
                <Route exact path="/dashboard" Component={Dashboard}></Route>
                {/* === User Routes === */}
                <Route path="/user">
                    <Route exact path="jobs" Component={Jobs}></Route>
                    <Route exact path="saved-jobs" Component={ShortListedJobs}></Route>
                    <Route exact path="job/:jobId" Component={JobDetail}></Route>
                    <Route exact path="jobrequest" Component={JobRequset}></Route>
                    <Route exact path="job-request-step1" Component={AddJobRequestStep1}></Route>
                    <Route exact path="job-request-step2" Component={JobRequestStep2}></Route>
                    <Route exact path="job-request-step3" Component={JobRequestStep3}></Route>



                    <Route exact path="company-profile/:cId" Component={UserCompanyProfile}></Route>
                    <Route exact path="applied-jobs" Component={AppliedJobs}></Route>
                    <Route exact path="calendar" Component={Calendar}></Route>
                    <Route exact path="messages" Component={Messages}></Route>
                    <Route exact path="documents" Component={Documents}></Route>
                    <Route exact path="document-share-history" Component={DocumentShareHistory}></Route>
                    <Route exact path="document-access-requests" Component={DocumentAccessRequests}></Route>
                    <Route exact path="compliance-lists" Component={ComplianceLists}></Route>
                    {/*<Route exact path="profile" Component={Profile} refreshPage={refreshPage}></Route>*/}
                    <Route exact path="profile" element={<Profile refreshPage={refreshPage} />}></Route>
                    {/* <Route exact path="subscription-plans" Component={SubscriptionPlans}></Route>*/}
                    <Route exact path="checkout" Component={Checkout}></Route>
                    <Route exact path="skill-checklists" Component={Checklists}></Route>
                    <Route exact path="skill-checklist/:checklistId" Component={ChecklistDetails}></Route>
                    <Route exact path="settings" Component={Settings}></Route>
                </Route>
            </Routes>
        </div>
    )
}

export default UserApp;