import React from 'react'
import SubscriptionList from './SubscriptionList';

const ActiveSubscriptions = ({data, executeCancelSubscription}) => {
  const filteredData = data.filter((subscription) => subscription.status === "active");
  return (
    <SubscriptionList data={filteredData} executeCancelSubscription={executeCancelSubscription} />
  )
}

export default ActiveSubscriptions;