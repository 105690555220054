import { useMemo } from 'react';

const fallbackData = { label: null, remainingDays: null, totalDays: null };

const useRemainingDays = ({ subscribedPlan }) => {
  const { start_date, end_date, plan } = subscribedPlan;
  const { type } = plan;

  return useMemo(() => {
    // Early exit if type is 'pay_as_you_go'
    if (type === 'pay_as_you_go') return fallbackData;

    // Check if dates are valid
    if (!start_date || !end_date) return fallbackData;

    // Convert dates to the beginning of the day (ignore time differences)
    const start = new Date(start_date.split(" ")[0]); // e.g., "2024-12-23"
    const end = new Date(end_date.split(" ")[0]);     // e.g., "2025-12-23"
    const now = new Date(new Date().toISOString().split("T")[0]); // Today's date only (ignores time)

    // Validate dates
    if (isNaN(start.getTime()) || isNaN(end.getTime()) || start >= end) {
      return fallbackData; // Invalid or incorrectly provided dates
    }

    // Calculate total days and remaining days
    const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24)); // Total days
    const remainingDays = Math.max(Math.ceil((end - now) / (1000 * 60 * 60 * 24)), 0); // Remaining days

    // Handle expired state
    if (remainingDays <= 0) {
      return { label: 'Expired', remainingDays: 0, totalDays };
    }

    return {
      label: `${remainingDays}/${totalDays} days`,
      remainingDays,
      totalDays,
    };
  }, [type, start_date, end_date]);
};

export default useRemainingDays;
