import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getTokenData } from "../utils/tokenUtils";
import { getRoleById, getPageTitle } from "../utils";

function usePageTitle() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const user = getTokenData();
  const currentRole = getRoleById(user?.role_id || "");

  useEffect(() => {
    const newTitle = getPageTitle(location.pathname, currentRole);
    setPageTitle(newTitle);
  }, [location.pathname, currentRole]);

  return pageTitle;
}

export default usePageTitle;