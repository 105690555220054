import { useState, useEffect, useCallback } from "react";
// import * as api from "../../services/ApiService";
import * as api from "../services/ApiService";
import useAuth from "./useAuth";
const useAnnouncement = (moduleFilter) => {
    const {isAuthenticated} = useAuth();
    const [showAnnouncement, setShowAnnouncement] = useState(true);
    const [announcement, setAnnouncement] = useState(null);
    useEffect(() => {
        const fetchAnnouncementData = async () => {
            try {
                const param = moduleFilter ? { module: moduleFilter } : {}; // Include module parameter only if it's set
                const result = await api.postMethod(param, "admin/get-announcements");
                if (result.status) {
                    const item = result.data.find(item => item.module === moduleFilter);
                    if(item){
                        setAnnouncement(item.description);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if(isAuthenticated){
            fetchAnnouncementData();
            const storedShowAnnouncement = localStorage.getItem(`showAnnouncement_${moduleFilter}`);
            if (storedShowAnnouncement !== null) {
                setShowAnnouncement(JSON.parse(storedShowAnnouncement));
            }
        }
    }, [moduleFilter, isAuthenticated]); // Empty dependency array ensures that the effect runs only once, when the component mounts

    const handleDismiss = useCallback(() => {
        setShowAnnouncement(false);
        localStorage.setItem(`showAnnouncement_${moduleFilter}`, false);
    }, [moduleFilter]);

    const handleShow = useCallback(() => {
        setShowAnnouncement(true);
        localStorage.setItem(`showAnnouncement_${moduleFilter}`, true);
    }, [moduleFilter]);

    return {
        showAnnouncement,
        announcement,
        handleShow,
        handleDismiss
    }
};

export default useAnnouncement;