import React from 'react';

const MoniterSubscription = ({ data }) => {
  // Filter out inactive subscriptions
  const activeSubscriptions = data.filter(subscription => subscription.status === 'active');

  // Group subscriptions by plan name
  const groupedData = activeSubscriptions.reduce((acc, subscription) => {
    const planName = subscription.plan.name;
    const billingCycleType = subscription.billing_cycle.type;

    // Initialize the plan if not already present
    if (!acc[planName]) {
      acc[planName] = {
        totalUsers: 0, // Total users for this plan across all billing cycles
        billingCycles: {} // Details grouped by billing cycle type
      };
    }

    // Initialize the billing cycle if not already present
    if (!acc[planName].billingCycles[billingCycleType]) {
      acc[planName].billingCycles[billingCycleType] = {
        amount: parseFloat(subscription.billing_cycle.price),
        totalUsers: 0, // Total users for this specific billing cycle
      };
    }

    // Increment the user count for the plan and the specific billing cycle
    acc[planName].totalUsers += 1;
    acc[planName].billingCycles[billingCycleType].totalUsers += 1;

    return acc;
  }, {});

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
      {Object.keys(groupedData).map((planName) => (
        <div
          className="card pt-2"
          style={{ width: "25%", display: "flex", flexDirection: "column", alignItems: "center", boxShadow: "1px 1px 1px 1px #00000073", margin: "10px" }}
          key={planName}
        >
          <i className="fas fa-podcast" style={{ fontSize: "5rem" }} />
          <strong className="card-title mt-2">{planName}</strong>

          <div className="card-body">
            <div>
              <strong>Total Users: </strong>
              {groupedData[planName].totalUsers}
            </div>

            {/* Render billing cycle details */}
            {Object.keys(groupedData[planName].billingCycles).map((cycleType) => (
              <div key={cycleType} style={{ marginTop: "10px", padding: "5px", border: "1px solid #ccc", borderRadius: "5px" }}>
                <strong>Billing Cycle: </strong> {cycleType}
                <div>
                  <strong>Amount: </strong> ${groupedData[planName].billingCycles[cycleType].amount}
                </div>
                <div>
                  <strong>Users: </strong> {groupedData[planName].billingCycles[cycleType].totalUsers}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MoniterSubscription;