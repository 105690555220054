
import { toast } from "react-toastify";
import { useCallback, useRef   } from "react";
import { apiRequest } from "../../services/ApiRequest";

const useSubscriptionCancel = ({callback}) => {
  const isExecuting = useRef(false);
  const execute = useCallback(async(subscription) => {
    if (isExecuting.current) {
      console.warn("Cancellation is already in progress.");
      return;
    }
    if (!subscription || !subscription.paypal_ref_id) {
      toast.warn("No valid subscription found to cancel.");
      return;
    }

    isExecuting.current = true;

    try {
      const response = await apiRequest("admin/subscription/cancel", "POST", {
        "paypal_ref_id": subscription.paypal_ref_id
      });

      if (response.success) {
        toast.info(response.message);        
        callback();
      }

      if (response.success === false) {
        toast.error(response.error);        
        callback();
      }
    } catch (err) {
      console.error("Cancel subscription error:", err);
    } finally {
      isExecuting.current = false;
    }
  }, [callback]);

  return { execute };
};

export default useSubscriptionCancel;