import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import * as auth from "../../../services/AuthService";
import * as api from "../../../services/ApiService";
import useFilteredMenu from "../../../hooks/useFilteredMenu";

const menuConfig = [
  {
    title: "Navigation",
    items: [
      {
        path: "dashboard",
        icon: "fas fa-home",
        text: "Dashboard",
        alwaysVisible: true,
      },
      {
        path: "company-profile",
        icon: "fas fa-user-tie",
        text: "Company Profile",
        alwaysVisible: true,
      },
      {
        path: "subscription-plans",
        icon: "fas fa-dollar-sign",
        text: "Subscription Plans",
        alwaysVisible: true,
      },
      {
        path: "my-subscription",
        icon: "fas fa-info-circle",
        text: "My Subscription",
        alwaysVisible: true,
      },
    ],
  },
  {
    title: "JOBS",
    items: [
      {
        path: "job-postings",
        icon: "fas fa-briefcase",
        text: "Job Postings",
        module: "Recruitment Tools",
        feature: "Job Posting Slots",
      },
      {
        path: "job-request",
        icon: "fas fa-briefcase",
        text: "Job Request",
        alwaysVisible: true,
      },
    ]
  },
  {
    title: "HR Management",
    items: [
      {
        path: "job-postings",
        icon: "fas fa-briefcase",
        text: "Job Postings",
        module: "Recruitment Tools",
        feature: "Job Posting Slots",
      },
      {
        path: "job-applications",
        icon: "fas fa-users",
        text: "Applicants",
        module: "Recruitment Tools",
        feature: "Applicant Tracking",
      },
      {
        path: "follow-ups",
        icon: "fas fa-podcast",
        text: "Follow Ups",
        module: "Recruitment Tools",
        feature: "Follow Up Management",
      },
      {
        path: "candidates",
        icon: "fas fa-users",
        text: "Candidates",
        alwaysVisible: true,
      },
      {
        path: "employees",
        icon: "fas fa-users",
        text: "Employees",
        alwaysVisible: true,
      },                   
    ],
  },
  {
    title: "Compliance Files",
    items: [
      {
        path: "compliance-files",
        icon: "fas fa-folder",
        text: "Manage Compliance Files",
        module: "Compliance File Tools",
        feature: "Manage Employee Compliance Files",
      },
      {
        path: "assign-checklist",
        icon: "fas fa-edit",
        text: "Skill Checklists",
        module: "Compliance File Tools",
        feature: "Skills Checklists",
      },
      {
        path: "facility-compliances",
        icon: "fas fa-list",
        text: "Facility Compliance Lists",
        module: "Compliance File Tools",
        feature: "Skills Checklists",
      },
    ],
  },
  {
    title: "Account Management",
    items: [
      {
        path: "assignments",
        icon: "fas fa-edit",
        text: "Assignments",
        module: "Account Management Tools",
        feature: "Manage Employees on Assignment",
      },
      {
        path: "submissions",
        icon: "fas fa-paper-plane",
        text: "Submissions",
        module: "Account Management Tools",
        feature: "Manage Submissions",
      },
    ],
  },
  {
    title: "Admin",
    items: [
      {
        path: "office-admins",
        icon: "fas fa-users",
        text: "Office Admins",
        module: "Administration & Management Tools",
        feature: "Task Management",
      },
      {
        path: "tasks",
        icon: "fas fa-tasks",
        text: "Tasks",
        module: "Administration & Management Tools",
        feature: "Task Management",
      },
      {
        path: "calendar",
        icon: "fas fa-calendar-alt",
        text: "Calendar",
        module: "Administration & Management Tools",
        feature: "Calendar & Reminders",
      },
    ],
  },
];

function SidebarClient({ user }) {
  const navigate = useNavigate();
  const location = useLocation();
  const filteredMenu = useFilteredMenu(menuConfig);

  // Determine if user is staff
  const isStaff = user.role_id === 6;

  // Dynamically set the URL slug
  const urlSlug = isStaff ? "staff" : "client";

  // Update role if applicable
  if (isStaff && (user.created_by_role_id === 2 || user.created_by_role_id === 3)) {
    user.role_id = user.created_by_role_id;
  }

  const handleLogout = async () => {
    try {
      const res = await auth.logout({ user_id: user.id, token: user.token }, `${urlSlug}/logout`);
      if (res) {
        setTimeout(() => navigate(`/${urlSlug}/login`), 500);
      }
    } catch (error) {
      console.error("Unexpected error structure:", error);
    }
  };

  const getClassNames = (path) => 
    location.pathname === path ? "menu-item menuitem-active" : "menu-item";

  const [messages, setMessages] = useState(0);
  const [newJobApplicationCounts, setNewJobApplicationCounts] = useState(0);

  useEffect(() => {
    const fetchSidebarMeta = async () => {
      try {
        const param = { user_id: user.id };
        const result = await api.postMethod(param, `${urlSlug}/get-sidebar-data`);
        if (result.status) {
          setMessages(result.data.messages);
          setNewJobApplicationCounts(result.data.applications);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    let interval = null;
    if (user && user.id) {
      //fetchSidebarMeta();
      //interval = setInterval(fetchSidebarMeta, 5000);
    }

    return () => clearInterval(interval);
  }, [user, urlSlug]);

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     try {
  //       const param = { user_id: user.id };
  //       const result = await api.postMethod(param, `${urlSlug}/get-sidebar-data`);
  //       if (result.status) {
  //         setMessages(result.data.messages);
  //         setNewJobApplicationCounts(result.data.applications);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   let interval = null;
  //   if (user && user.id) {
  //     fetchSidebarMeta();
  //     interval = setInterval(fetchSidebarMeta, 5000);
  //   }

  //   return () => clearInterval(interval);
  // }, [user, urlSlug]);

  // Menu configuration
  // const menuConfig = [
  //   { title: "Navigation", items: [
  //     { path: `/${urlSlug}/dashboard`, icon: "fas fa-home", text: "Dashboard" },
  //     { 
  //       path: `/${urlSlug}/inbox`, 
  //       icon: "fas fa-comments", 
  //       text: "Messaging", 
  //       badge: messages, 
  //       children: [
  //         { path: `/${urlSlug}/inbox`, text: "Inbox", badge: messages }
  //       ]
  //     },
  //     { path: `/${urlSlug}/calendar`, icon: "fas fa-calendar-alt", text: "Calendar" },
  //   ]},
  //   { title: "Jobs", items: [
  //     { path: `/${urlSlug}/job-postings`, icon: "fas fa-briefcase", text: "Job Postings" },
  //     { path: `/${urlSlug}/job-request`, icon: "fas fa-briefcase", text: "Job Request" },
  //   ]},
  //   { title: "HR Management", items: [
  //     { path: `/${urlSlug}/follow-ups`, icon: "fas fa-podcast", text: "Follow Ups" },
  //     { path: `/${urlSlug}/job-applications`, icon: "fas fa-users", text: "Applicants" },
  //     { path: `/${urlSlug}/candidates`, icon: "fas fa-users", text: "Candidates" },
  //     !isStaff && { path: `/${urlSlug}/employees`, icon: "fas fa-users", text: "Employees" },
  //   ].filter(Boolean)},
  //   { title: "Compliance Files", items: [
  //     { path: `/${urlSlug}/compliance-files`, icon: "fas fa-folder", text: "Manage" },
  //     { path: `/${urlSlug}/assign-checklist`, icon: "fas fa-edit", text: "Skill Checklists" },
  //     { path: `/${urlSlug}/facility-compliances`, icon: "fas fa-list", text: "Facility Compliance Lists" },
  //   ]},
  //   { title: "Tasks", items: [
  //     { path: `/${urlSlug}/tasks`, icon: "fas fa-tasks", text: "Tasks" },
  //   ]},
  //   { title: "Account Management", items: [
  //     user.role_id === 3 && { path: `/${urlSlug}/subscription-plans`, icon: "fas fa-dollar-sign", text: "Subscription Plans" },
  //     user.role_id === 3 && { path: `/${urlSlug}/my-subscription`, icon: "fas fa-info-circle", text: "My Subscription" },
  //     { path: `/${urlSlug}/submissions`, icon: "fas fa-paper-plane", text: "Submissions" },
  //   ].filter(Boolean)},
  //   { title: "Admin", items: [
  //     !isStaff && { path: `/${urlSlug}/company-profile`, icon: "fas fa-user-tie", text: "Company Profile" },
  //     !isStaff && { path: `/${urlSlug}/office-admins`, icon: "fas fa-users", text: "Office Admins" },
  //   ].filter(Boolean)}
  // ];

  console.log(filteredMenu);

  return (
    <ul className="menu">
      {filteredMenu.map((section, index) => (
        <React.Fragment key={index}>
          {section.items && section.items.length > 0 && (
            <li className="menu-title">{section.title}</li>
          )}
          {section.items.map((item, i) => (
            item && (
              <li key={i} className={getClassNames(item.path)}>
                <NavLink to={`/client/${item.path}`} className="menu-link" activeClassName="active">
                  <span className="menu-icon"><i className={item.icon} /></span>
                  <span className="menu-text">{item.text}</span>
                  {item.badge > 0 && <span className="badge badge-outline-primary badge-pill ms-auto">{item.badge}</span>}
                </NavLink>
              </li>
            )
          ))}
        </React.Fragment>
      ))}
      <li className="menu-item">
        <NavLink to="#" onClick={handleLogout} className="menu-link">
          <span className="menu-icon"><i className="fas fa-sign-out-alt" /></span>
          <span className="menu-text">Logout</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default SidebarClient;