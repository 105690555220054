import { useState, useEffect } from 'react';

export default function useAuth() {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkAuthentication = () => {
      const token = localStorage.getItem("token");
      setIsAuthenticated(!!token); // Set authentication based on token presence
  };

  useEffect(() => {
    // Initial check when component is mounted
    checkAuthentication();
    setLoading(false);

    // Optional: Listen for changes in localStorage to detect token changes
    const handleStorageChange = () => {
      checkAuthentication();
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return { isAuthenticated, loading };
}