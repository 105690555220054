import { create } from 'zustand';
import { apiRequest } from '../services/ApiRequest';

const initialStore = {
  data: [],
  loading: false,
  error: null
};

const usePlanStore = create((set) => ({
  ...initialStore,

  fetch: async () => {
    set({ ...initialStore, loading: true });
    try {
      const response = await apiRequest("client/plans", "GET");
      if (response.success) {
        set({ data: response.data, loading: false });
      } else {
        set({
          ...initialStore,
          error: response.message || "Failed to fetch plans",
        });
      }
    } catch (err) {
      set({
        ...initialStore,
        error: err.message || "Network error occurred. Please check your connection.",
      });
      console.error("Fetch Plans Error:", err);
    }
  },

  refetch: () => {
    set({ ...initialStore, loading: true });
    setTimeout(() => {
      usePlanStore.getState().fetch();
    }, 0);
  },

  reset: () => set(initialStore),
}));

export default usePlanStore;