import React from 'react';

const SubscriptionCard = ({ subscription, executeCancelSubscription }) => {
  const defaultProfilePic = `${process.env.PUBLIC_URL}/assets/images/fav-icon.jpg`;
  const userProfilePic = subscription?.user?.profile_pic 
    ? `${process.env.REACT_APP_FRONT_END_URL}uploads/users/${subscription.user.profile_pic}` 
    : defaultProfilePic;
  return (
    <div className="mt-2" key={subscription.id}>
      <div className="card mb-0 pt-2">
        <div className="row g-0 d-flex">
          <div className="col-md-2" style={{ marginLeft: "2vw" }}>
            <img
              src={userProfilePic}
              alt={`${subscription.user.name}'s profile`}
              className="img-fluid rounded-circle"
              style={{ width: '80px', height: "80px" }}
            />
            <div className="mt-2">
              <h5 className="card-title">
                <strong>Name:</strong> {subscription.user.name}
              </h5>
            </div>
          </div>

          <div className="col-md-5">
            <div className="card-body">
              <p className="card-text">
                <strong>Email:</strong> {subscription.user.email}
              </p>
              <p className="card-text">
                <strong>Phone Number:</strong> {subscription.user.phone || 'N/A'}
              </p>
              <p className="card-text" style={{ textTransform: 'capitalize' }}>
                <strong>Status:</strong> {subscription.status}
              </p>
              {subscription.status === "active" && subscription.plan.type === "recurring" && (
                <p className="card-text">
                  <strong>Action: </strong>
                  {subscription.cancelled_on_paypal === false && (
                    <button
                      onClick={() => executeCancelSubscription(subscription)}
                      className="btn btn-danger text-white px-2 py-1 rounded"
                    >
                      Cancel
                    </button>
                  )}
                  {subscription.cancelled_on_paypal === true && (
                    <span className='badge bg-danger text-white rounded-pill px-2 py-1'>End-of-Term Cancellation</span>
                  )}
                </p>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="card-body">
              <p className="card-text">
                <strong>Plan Type:</strong> {subscription.plan.name}
              </p>
              <p className="card-text">
                <strong>Start Date:</strong> {new Date(subscription.start_date).toLocaleDateString()}
              </p>
              <p className="card-text">
                <strong>End Date:</strong> {subscription.end_date ? new Date(subscription.end_date).toLocaleDateString() : 'N/A'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SubscriptionList = ({ data, executeCancelSubscription }) => {
  return (
    <>
      {data.map((subscription, index) => (
        <SubscriptionCard 
          key={index}
          subscription={subscription} 
          executeCancelSubscription={executeCancelSubscription} 
        />
      ))}
    </>
  );
};

export default SubscriptionList;